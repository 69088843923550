import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query PostBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(format: PLAIN, pruneLength: 49)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MM D, YYYY")
        description
        image {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`

class PostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const excerpt = post.excerpt
    const { slug } = post.fields
    const { description, image, title } = post.frontmatter

    return (
      <Layout>
        <SEO
          description={description || excerpt}
          title={title}
          type="article"
        />
        <div>
          <Link activeStyle={{ textDecoration: "none" }} to={slug}>
            <h2>{title}</h2>
          </Link>
          {image ? <Image fluid={image.childImageSharp.fluid} /> : null}
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr />
          <Link to="/">Go back to the homepage</Link>
        </div>
      </Layout>
    )
  }
}

export default PostTemplate
